import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { decode } from '../components/helper';

import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = () => {
  const [details, setDetails] = useState({ mail: '', tel: '' });
  useEffect(() => {
    const mail = decode('ZV9kXy5fbV9oX2Vfcl8tX2lfZV9yX2VfZl9wX2Vfb190X0BfZV90X2FfZV9i');
    const tel = decode('N182XzdfN18vXzNfM18zXzdfMA==');
    setDetails({ mail, tel });
  }, []);

  return (
    <Layout>
      <Seo title="Home" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="3000"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <StaticImage
                    src="../images/slide1.jpg"
                    quality={95}
                    formats={['AUTO', 'WEBP']}
                    alt="Töpferware"
                    className="img-fluid"
                  ></StaticImage>
                </div>
                <div className="carousel-item">
                  <StaticImage
                    src="../images/slide2.jpg"
                    quality={95}
                    formats={['AUTO', 'WEBP']}
                    alt="Töpferware"
                    className="img-fluid"
                  ></StaticImage>
                </div>
                <div className="carousel-item">
                  <StaticImage
                    src="../images/slide3.jpg"
                    quality={95}
                    formats={['AUTO', 'WEBP']}
                    alt="Töpferware"
                    className="img-fluid"
                  ></StaticImage>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="display-5 my-4 text-center">Kontakt</div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 my-3 my-md-0 text-center">
                  <span className="fs-5">Töpferei</span>
                  <br></br>
                  geöffnet Samstags 10-13 Uhr
                </div>
                <div className="col-12 col-md-4 my-3 my-md-0 text-center">
                  <span className="fs-5"> {details.tel}</span> <br></br>
                  <a href={'mailto:' + details.mail}>{details.mail}</a>
                </div>
                <div className="col-12 col-md-4 my-3 my-md-0 text-center">
                  <span className="fs-5">72589 Westerheim</span>
                  <br></br> Lange Gasse 22
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
